<template>
  <v-container>
    <regional-users @allow-permit="permit = true" />
    <branch-users v-if="permit" />
  </v-container>
</template>
<script>
  import RegionalUsers from '../component/RegionalUsers.vue'
  import BranchUsers from '../component/BranchUsers.vue'
  export default {
    components: {
      RegionalUsers,
      BranchUsers,
    },
    data: () => ({
      permit: false,
    }),
  }
</script>
