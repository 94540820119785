<template>
  <v-container>
    <v-row class="d-flex justify-space-around">
      <v-card
        width="33%"
        tile
      >
        <v-progress-linear
          v-if="loadingCustomer"
          indeterminate
          color="primary"
        />
        <v-list-item-content
          v-if="!loadingCustomer"
          class="px-3 py-2"
        >
          <v-list-item-subtitle><v-icon class="mdi mdi-account px-2 py-1" /> {{ $t('name') }}: <span class="float-right mt-2">{{ clickedCustomer.customerName }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-account-check px-2 py-1" /> {{ $t('contactPerson') }}: <span class="float-right mt-2">{{ clickedCustomer.contactPerson }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-email px-2 py-1" /> {{ $t('email') }}: <span class="float-right mt-2">{{ clickedCustomer.contactEmail }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-phone px-2 py-1" /> {{ $t('phone-number') }}: <span class="float-right mt-2">{{ clickedCustomer.contactPhone }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-cloud px-2 py-1" /> {{ $t('status') }}: <span class="float-right mt-2">{{ clickedCustomer.status === 1 ? 'Active' : 'Inactive' }}</span></v-list-item-subtitle>
        </v-list-item-content>
      </v-card>
      <v-card
        width="33%"
        tile
      >
        <v-progress-linear
          v-if="loadingRegionalOffice || loadingBranchOffice"
          indeterminate
          color="primary"
        />
        <v-list-item-content
          v-if="!loadingRegionalOffice && item.Type === 'regionOffice'"
          class="px-3 py-2"
        >
          <v-list-item-subtitle><v-icon class="mdi mdi-account px-2 py-1" /> {{ $t('regionalOfficeName') }}: <span class="float-right mt-2">{{ clickedRegionalOffice.regionalOfficeName }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-account-check px-2 py-1" /> {{ $t('contactPerson') }}: <span class="float-right mt-2">{{ clickedRegionalOffice.contactPerson }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-email px-2 py-1" /> {{ $t('contactEmail') }}: <span class="float-right mt-2">{{ clickedRegionalOffice.contactEmail }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-phone px-2 py-1" /> {{ $t('contactPhone') }}: <span class="float-right mt-2">{{ clickedRegionalOffice.contactPhone }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-cloud px-2 py-1" /> {{ $t('status') }}: <span class="float-right mt-2">{{ clickedRegionalOffice.status === 1 ? 'Active' : 'Inactive' }}</span></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-content
          v-if="!loadingBranchOffice && item.Type === 'branchOffice'"
          class="px-3 py-2"
        >
          <v-list-item-subtitle><v-icon class="mdi mdi-account px-2 py-1" /> {{ $t('branchOfficeName') }}: <span class="float-right mt-2">{{ clickedBranchOffice.branchOfficeName }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-account-check px-2 py-1" /> {{ $t('contactPerson') }}: <span class="float-right mt-2">{{ clickedBranchOffice.contactPerson }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-email px-2 py-1" /> {{ $t('contactEmail') }}: <span class="float-right mt-2">{{ clickedBranchOffice.contactEmail }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-phone px-2 py-1" /> {{ $t('contactPhone') }}: <span class="float-right mt-2">{{ clickedBranchOffice.contactPhone }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-cloud px-2 py-1" /> {{ $t('status') }}: <span class="float-right mt-2">{{ clickedBranchOffice.status === 1 ? 'Active' : 'Inactive' }}</span></v-list-item-subtitle>
        </v-list-item-content>
      </v-card>
      <v-card
        width="33%"
        tile
      >
        <v-progress-linear
          v-if="loadingUser"
          indeterminate
          color="primary"
        />
        <v-list-item-content
          v-if="!loadingUser"
          class="px-3 py-2"
        >
          <v-list-item-subtitle><v-icon class="mdi mdi-account px-2 py-1" /> {{ $t('firstname') }}: <span class="float-right mt-2">{{ clickedUser.firstName }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-account-check px-2 py-1" /> {{ $t('lastname') }}: <span class="float-right mt-2">{{ clickedUser.lastName }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-email px-2 py-1" /> {{ $t('email') }}: <span class="float-right mt-2">{{ clickedUser.email }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-phone px-2 py-1" /> {{ $t('phone-number') }}: <span class="float-right mt-2">{{ clickedUser.phone }}</span></v-list-item-subtitle>
          <v-list-item-subtitle><v-icon class="mdi mdi-cloud px-2 py-1" /> {{ $t('status') }}: <span class="float-right mt-2">{{ clickedUser.status === 1 ? 'Active' : 'Inactive' }}</span></v-list-item-subtitle>
        </v-list-item-content>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
  import { mapState } from 'vuex'
  export default {
    props: {
      item: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data: () => ({
      loadingCustomer: false,
      loadingRegionalOffice: false,
      loadingUser: false,
      loadingBranchOffice: false,
      clickedCustomer: {
        customerName: '',
        contactEmail: '',
        contactPerson: '',
        contactPhone: '',
        status: '',
      },
      clickedBranchOffice: {
        branchOfficeName: '',
        contactEmail: '',
        contactPerson: '',
        contactPhone: '',
        status: '',
      },
      clickedRegionalOffice: {
        regionalOfficeName: '',
        contactEmail: '',
        contactPerson: '',
        contactPhone: '',
        status: '',
      },
      clickedUser: {
        firstName: '',
        lastName: '',
        contactPerson: '',
        phone: '',
        status: '',
      },
    }),
    computed: {
      ...mapState({
        selectedCustomer: (state) => state.customer.selectedCustomer,
        selectedUser: (state) => state.user.selectedUser,
        selectedRegionalOffice: (state) => state.regionaloffice.selectedRegionalOffice,
        selectedBranchOffice: (state) => state.branchOffice.selectedBranchOffice,
      }),
    },
    async mounted () {
      await this.getSelectedCutomer()
      await this.getSelectedUser()
      if (this.item.Type === 'branchOffice') {
        await this.getSelectedBranchOffice()
      } else {
        await this.getSelectedRegionalOffice()
      }
    },
    methods: {
      async getSelectedCutomer () {
        try {
          this.loadingCustomer = true
          await this.$store.dispatch('customer/getCustomerById', this.item.CustomerId)
            .then(() => {
              Object.assign(this.clickedCustomer, this.selectedCustomer)
              this.loadingCustomer = false
            })
        } catch (err) {
        }
      },
      async getSelectedUser () {
        try {
          this.loadingUser = true
          await this.$store.dispatch('user/getUserById', this.item.UserId)
            .then(() => {
              Object.assign(this.clickedUser, this.selectedUser)
              this.loadingUser = false
            })
        } catch (err) {
        }
      },
      async getSelectedRegionalOffice () {
        try {
          this.loadingRegionalOffice = true
          await this.$store.dispatch('regionaloffice/getRegionalOfficesById', this.item.RegionalOfficeId)
            .then(() => {
              Object.assign(this.clickedRegionalOffice, this.selectedRegionalOffice.regionalOfficeDB)
              this.loadingRegionalOffice = false
              this.loadingBranchOffice = false
            })
        } catch (err) {
        }
      },
      async getSelectedBranchOffice () {
        try {
          this.loadingBranchOffice = true
          await this.$store.dispatch('branchOffice/getBranchOfficesById', this.item.BranchOfficeId)
            .then(() => {
              Object.assign(this.clickedBranchOffice, this.selectedBranchOffice.branchOfficeDB)
              this.loadingBranchOffice = false
              this.loadingRegionalOffice = false
            })
        } catch (err) {
        }
      },
    },
  }
</script>
