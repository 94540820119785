<template>
  <v-container
    fluid
    tag="section"
    class="mt-1"
  >
    <v-card>
      <v-card-title class="card-title">
        <h4>{{ $t('regionalstaff') }}</h4>
        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="mainHeaders"
        :items="regionalusers"
        :items-per-page="regionalusers.length"
        :footer-props="footerProps"
        :search="search"
        :single-expand="true"
        :expanded.sync="expanded"
        dense
        sort-by="Id"
        item-key="Id"
        fluid
        fixed-header
        style="cursor:pointer"
        class="elevation-1"
        @click:row="expand"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-spacer />
            <v-spacer />
            <v-spacer />
            <v-spacer />

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            />
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers , item }">
          <td :colspan="headers.length">
            <v-row no-gutters>
              <regional-user-details-card
                :item="item"
              />
            </v-row>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import RegionalUserDetailsCard from '../Cards/RegionalUserDetailsCard.vue'
  export default {
    components: {
      RegionalUserDetailsCard,
    },
    data: () => ({
      search: '',
      flag: true,
      footerProps: { 'items-per-page-options': [-1, 5, 10, 15] },
      regionalusers: [],
      expanded: [],
      selectedWarehouse: {},
      statuses: [
        {
          key: 'All',
          title: 'All',
        },
        {
          key: 'Active',
          title: 'Active',
        },
        {
          key: 'Inactive',
          title: 'Inactive',
        },
      ],
    }),
    computed: {
      ...mapState({
        staffFromRepo: (state) => state.regionalofficeuser.regionalofficeuser,
      }),
      mainHeaders () {
        return [
          { text: this.$t('Id'), value: 'Id', align: 'start' },
          { text: this.$t('customerId'), value: 'CustomerId' },
          { text: this.$t('regionalOfficeId'), value: 'RegionalOfficeId' },
          { text: this.$t('userId'), value: 'UserId' },
          { text: this.$t('actions'), value: 'actions', sortable: false },
        ]
      },
    },
    async created () {
      await this.initialize()
    },
    methods: {
      initialize () {
        this.getRegionalUser()
      },
      getRegionalUser () {
        this.$store.dispatch('regionalofficeuser/getRegionalUser')
          .then(() => {
            this.regionalusers = this.staffFromRepo
            this.$emit('allow-permit')
          })
      },
      async expand (item) {
        if (this.flag) {
          await this.$store.dispatch('user/getUserById', item.Id)
          if (item === this.expanded[0]) this.expanded = []
          else this.expanded = [item]
        }
      },
    },
  }
</script>
